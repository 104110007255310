import { Provider } from 'react-redux'
import type { AppProps } from 'next/app'
import Head from 'next/head';
import { store } from '@redux/store'
import '../public/css/style.scss';
import { SSRProvider } from 'react-bootstrap';

function NextApp({ Component, pageProps }: AppProps)
{
    return (
        <SSRProvider>
            <Provider store={store}>
                <Head>
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                </Head>
                
                <Component {...pageProps} />
            </Provider>
        </SSRProvider>
    )
}

export default NextApp