import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityViewSettings } from '@xFrame4/components/admin/EntityView';
import { BusinessEntityFilter } from '@xFrame4/business/base/BusinessEntity';

interface EntityManagementState
{
    settings: EntityViewSettings[]; 
}

const initialState: EntityManagementState = {
    settings: []  
};

export const entityManagementSlice = createSlice({
    name: 'entityManagement',
    initialState,
    reducers: {
        storeFilterForEntityView: (state, action: PayloadAction< { id: string, filter: BusinessEntityFilter } >) => {
            let entityViewSettingsList = state.settings;
            let entityViewSettingsIndex = entityViewSettingsList.findIndex(s => s.id === action.payload.id);
            if (entityViewSettingsIndex >= 0)
            {
                entityViewSettingsList[entityViewSettingsIndex].filter = action.payload.filter;
            }
            else
            {
                entityViewSettingsList.push({ id: action.payload.id, filter: action.payload.filter });
            }
        }
    }
});

export const { storeFilterForEntityView } = entityManagementSlice.actions;

export default entityManagementSlice.reducer;
